import React from 'react'
import { Redirect } from 'react-router-dom'
import { Paper, Grid } from '@material-ui/core'
import ReferralForm from './ReferralForm'
import { LoadingSidebar } from './LoadingSkeletons'
import ConversationsTabs from '../Conversations'
import { ReferralActivity } from '../ReferralActivity'
import useApi from '../../hooks/useApi'
import PermissionDenied from '../PermissionDenied'

const useReferral = (referralId: string) => {
  const { data, refetch } = useApi({
    route: `/referral/${referralId}`,
  })

  if (data) {
    const { Referral, ...rest } = data
    return { referral: { ...Referral, ...rest }, refetch }
  }
  return { referral: null, refetch }
}

export const EditReferral: React.FC<any> = (props: any) => {
  const referralId = props.match.params.id
  const { referral, refetch } = useReferral(referralId)
  // permissions are implied, not explicit
  // so it's possible that an EDITOR will have view=false
  if (referral && referral.View === false && referral.Edit === false) {
    return <PermissionDenied />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <ReferralForm initialData={referral} isEditing refetch={refetch} />
      </Grid>
      <Grid item xs={4}>
        {referral ? (
          <>
            <Paper>
              <ConversationsTabs referralId={referralId} />
            </Paper>
            <Paper style={{ marginTop: '20px' }}>
              <ReferralActivity referralId={referralId} />
            </Paper>
          </>
        ) : (
          <LoadingSidebar />
        )}
      </Grid>
    </Grid>
  )
}

export const CreateReferral: React.FC<any> = (props: any) => {
  const member = props.location.state && props.location.state.record

  const { data: memberContact } = useApi(
    member && member.MemberID
      ? {
          route: `/member/${member.MemberID}/contact_info`,
        }
      : undefined
  )

  const { data: PlanExclusions } = useApi(
    member && member.EmployerMember && member.EmployerMember.EmployerID
      ? {
          route: `/employer/${member.EmployerMember.EmployerID}/plan_exclusions`,
        }
      : undefined
  )

  if (!member) return <Redirect to="/find_member" />
  if (!memberContact || !PlanExclusions) return null

  return (
    <ReferralForm
      initialData={{
        ...member,
        PlanExclusions,
        ContactPhone: memberContact.Phone,
        ContactPhoneExt: memberContact.PhoneExt,
        ContactEmail: memberContact.Email,
      }}
    />
  )
}
