import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Divider, Paper } from '@material-ui/core'
import { NoteAdd, Save } from '@material-ui/icons'
import MemberDetailsForm from './MemberDetailsForm'
import Exclusions from './Exclusions'
import ReferralDetailsForm from './ReferralDetailsForm'
import AttachedBundlesForm from './AttachedBundlesForm'
import PhysicianDetailsForm from './PhysicianDetailsForm'
import CloneReferralButton from '../Referrals/CloneReferralButton'
import ReferrerActionsBox from './ReferrerActionsBox'
import * as T from './types'
import validators from './validators'
import { useConfig } from '../../Config'

import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import { post, put } from '../../services/ApiService'
import useForm from '../../hooks/useForm'
import { COLORS } from '../../utils/colors'
import * as formatters from '../../utils/formatters'
import PromptDialogGenerateInvoice from '../Referrals/dialogGenerateInvoice'
import CancelDeclineDialog from './CancelDeclineDialog'
import { useAuth } from '../../Auth'
import { Alert } from '@material-ui/lab'
import referralStatuses from './statuses'
import ButtonArchiveReferral from '../Referrals/ButtonArchiveReferral'

interface Props {
  initialData?: T.ReferralFormData
  isEditing?: boolean
  refetch?: () => void
}

interface ActionsFooterProps {
  form: T.ReferralForm
  isEditing?: boolean
  setAttemptedSave: (val: boolean) => void
  refetch?: () => void
}

const FormDivider = styled(Divider)`
  margin: 20px 0px;
`

const Content = styled.div`
  padding: 20px 20px 0px;
`

const Footer = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
`

const StyledUrgent = styled(Alert)`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  background: #ff9e7b !important;
  color: #8a2c0a !important;
  position: sticky;
  top: 82px;
  z-index: 999;

  .MuiAlert-icon {
    color: inherit !important;
  }
`

const useReferralPostData = (data: T.ReferralFormData) => {
  // convert any data for the request
  return {
    ...data,
    ServiceDate: data.ServiceDate
      ? formatters.date.deformat(data.ServiceDate)
      : data.ServiceDate,
  }
}

const ActionsFooter: React.FC<ActionsFooterProps> = ({
  isEditing,
  form,
  setAttemptedSave,
  refetch,
}: ActionsFooterProps) => {
  const hist = useHistory()
  const loc: any = useLocation()
  const { show: showSnackbar } = useSnackbar()
  const data = useReferralPostData(form.data)
  const { adminAppUrl } = useConfig() as any
  const { access } = useAuth()
  const [isSaving, setIsSaving] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const canCreateLOAs = access.CanCreateLOAs
  const checkStatus = () => {
    if (
      form.data.StatusID === referralStatuses.REFERRAL_STATUS_CANCELLED &&
      !form.data.CancelReason
    ) {
      return setShowModal(true)
    }
    if (
      form.data.StatusID === referralStatuses.REFERRAL_STATUS_DECLINED &&
      !form.data.DeclineReason
    ) {
      return setShowModal(true)
    }
    save()
  }

  const save = async () => {
    setIsSaving(true)
    const valid = form.isValid()

    if (!valid) {
      showSnackbar('Please correct form errors.', SnackbarTypeError)
      setAttemptedSave(true)
      setIsSaving(false)
      return
    }

    try {
      if (isEditing) {
        await put(`/referral/${data.ID}`, data)
        setIsSaving(false)
        showSnackbar('Success', SnackbarTypeSuccess)
        goBackToReferrals()
        return
      }
      const res: any = await post(`/referral`, data)
      setIsSaving(false)
      showSnackbar('Success', SnackbarTypeSuccess)
      hist.push(`/referral/${res.Data.ID}`)
    } catch (e: any) {
      setIsSaving(false)
      showSnackbar(e?.Error?.[0] || 'Error saving referral', SnackbarTypeError)
    }
  }

  const goBackToReferrals = () =>
    hist.push(`/referrals${loc.state ? loc.state.prevQueryString : ''}`)

  // Only allow generating an invoice if a) perm is granted (via CanInvoice), and referral
  // is in correct/known status. HEADS UP: if you're going to rewrite this with a switch statement,
  // TEST IT
  function showGenerateInvoice(): boolean {
    if (!form.data.CanInvoice) return false
    if (form.data.StatusID === referralStatuses.REFERRAL_STATUS_SCHEDULED)
      return true
    if (form.data.StatusID === referralStatuses.REFERRAL_STATUS_COMPLETED)
      return true
    return false
  }

  function showCreateLOA(): boolean {
    return !!data.ID && canCreateLOAs
  }

  return (
    <>
      <CancelDeclineDialog
        showModal={showModal}
        setShowModal={setShowModal}
        form={form}
        submit={save}
        status={form.data.StatusID}
      />
      <Footer>
        <Button
          color="primary"
          variant="contained"
          disabled={isSaving}
          startIcon={<Save />}
          onClick={checkStatus}>
          Save
        </Button>
        {form.data.ID && (
          <CloneReferralButton
            size="medium"
            variant="contained"
            style={{
              margin: '0px 20px',
              backgroundColor: COLORS.PROVIDERS.FUSCIA,
            }}
            record={{ ID: form.data.ID }} // @todo-refactor remove 'record'
          />
        )}
        {showCreateLOA() && (
          <Button
            color="primary"
            variant="contained"
            disabled={isSaving}
            startIcon={<NoteAdd />}
            style={{
              backgroundColor: COLORS.PROVIDERS.EGGPLANT,
              margin: '0px 20px 0px 0px',
            }}
            target="_blank"
            rel="noreferrer"
            href={`${adminAppUrl}/loa?ReferralId=${data.ID}`}>
            Create LOA
          </Button>
        )}

        {showGenerateInvoice() && (
          <PromptDialogGenerateInvoice history={hist} record={form.data} /> // @todo-refactor remove 'record'
        )}

        {isEditing && (
          <ButtonArchiveReferral
            data={data}
            iconButtonOnly={false}
            onArchive={(id: number) => {
              refetch && refetch()
            }}
            style={{ float: 'right', backgroundColor: COLORS.BW.GUNMETAL }}
          />
        )}
      </Footer>
    </>
  )
}

const ReferralForm: React.FC<Props> = ({
  initialData,
  isEditing,
  refetch,
}: Props) => {
  const hist = useHistory()
  const loc: any = useLocation()

  const defaultData = {
    BundlerID: null,
    ContactEmail: null,
    ContactPhone: null,
    ContactPhoneExt: null,
    CreatedAt: null,
    CreatedByUser: null,
    CreatedByUserID: null,
    Descr: null,
    DeductibleStartDay: 0,
    DeductibleStartMonth: 0,
    EmployerMember: null,
    EmployerMemberID: null,
    EntityName: 'referral' as const,
    ReferralInvoiceID: null,
    IsSurgery: null,
    IsTravel: null,
    IsUrgent: null,
    Loas: [],
    MainCptCode: null,
    ModifiedByUserID: null,
    Notes: null,
    OrderingPhysEmail: null,
    OrderingPhysFax: null,
    OrderingPhysName: null,
    OrderingPhysPhone: null,
    OrderingPhysPhoneExt: null,
    OrganizationID: null,
    PCPAddressID: null,
    PracticeFacilityID: null,
    ReferralRequestID: null,
    ReferrerID: null,
    ReferrerDivisionID: null,
    ReferrerDivisionName: null,
    ReferrerName: null,
    ServiceDate: null,
    ServicingProvider: null,
    SourceReferralID: null,
    StatusID: null,
    TicketNr: null,
    UpdatedAt: null,
    EmployerName: '',
    EmployerTermDate: null,
    RequestingUserID: 0,
    PlanExclusions: [],
    View: true,
    Edit: true,
    CanInvoice: false,
    CancelReason: null,
    DeclineReason: null,
  }

  const form = useForm<T.ReferralFormData>(defaultData, validators)

  const [attemptedSave, setAttemptedSave] = React.useState(false)
  const { access } = useAuth()

  React.useEffect(() => {
    // set the initialData when it's finished loading
    if (!!initialData) {
      form.setData({ ...form.data, ...initialData })
    }
  }, [initialData])

  const goBackToReferrals = () =>
    hist.push(`/referrals${loc.state ? loc.state.prevQueryString : ''}`)

  return (
    <>
      {!!form.data.IsUrgent && (
        <StyledUrgent severity="warning">
          This referral is marked URGENT!
        </StyledUrgent>
      )}
      <Paper>
        <Content>
          {access.Roles.ReferralRequester && (
            <ReferrerActionsBox form={form} goBack={goBackToReferrals} />
          )}
          <MemberDetailsForm form={form} showErrors={attemptedSave} />
          <FormDivider />
          <Exclusions form={form} />
          <FormDivider />
          <ReferralDetailsForm
            form={form}
            showErrors={attemptedSave}
            isEditing={isEditing}
          />
          <FormDivider />
          <AttachedBundlesForm form={form} />
          <PhysicianDetailsForm form={form} showErrors={attemptedSave} />
        </Content>
        {form.data.Edit && (
          <ActionsFooter
            isEditing={isEditing}
            form={form}
            setAttemptedSave={setAttemptedSave}
            refetch={refetch}
          />
        )}
      </Paper>
    </>
  )
}

export default ReferralForm
