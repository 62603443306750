import React from 'react'
import moment from 'moment'
import FormHeader from './FormHeader'
import EmployerExclusions from '../EmployerExclusions/EmployerExclusions'
import { ReferralForm } from './types'
import styled from 'styled-components'

const StyledPadding = styled.div`
  padding: 1rem 0;
`

interface Props {
  form: ReferralForm
}

const Exclusions: React.FC<Props> = (props) => {
  if (!props.form.data.PlanExclusions) return null
  const exclusions = props.form.data.PlanExclusions.filter(
    (ex: any) => ex.Status > 0
  )
  const deductibleStartDate = moment(
    `${props.form.data.DeductibleStartMonth}/${props.form.data.DeductibleStartDay}`
  ).format('MM/DD')
  const deductibleEndDate = moment(
    `${props.form.data.DeductibleStartMonth}/${props.form.data.DeductibleStartDay}`
  )
    .subtract(1, 'days')
    .format('MM/DD')

  const hasInvalidDates =
    deductibleEndDate === 'Invalid date' ||
    deductibleStartDate === 'Invalid date'
  return (
    <StyledPadding>
      <FormHeader title="Employer Exclusions" />
      <EmployerExclusions
        exclusions={exclusions}
        hasInvalidDates={hasInvalidDates}
        deductibleStartDate={deductibleStartDate}
        deductibleEndDate={deductibleEndDate}
      />
    </StyledPadding>
  )
}

export default Exclusions
